const initialState = null

export default (state = initialState, { type, id, data }) => {
  switch (type) {
    case 'SUPPLY_DOCUMENT_REQUESTING':
      return {
        ...state,
        [id]: {
          readyStatus: 'requesting'
        }
      }
    case 'SUPPLY_DOCUMENT_FAILURE':
      return {
        ...state,
        [id]: {
          readyStatus: 'failure'
        }
      }
    case 'SUPPLY_DOCUMENT_SUCCESS':
      return { ...state, [id]: { readyStatus: 'success', data } }
    default:
      return state
  }
}
