import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import supply_list from './supply_list'
import supply_documents from './supply_documents'
import tabs from './tabs'
import login_modal from './login_modal'

export default combineReducers({
  tabs,
  supply_list,
  supply_documents,
  login_modal,
  form: formReducer
})
