import React, { Component } from 'react'
import { CookiesProvider } from 'react-cookie'
import { ActionCableProvider } from 'react-actioncable-provider'
import { Sidebar, Topbar, Dashboard, LoginModal } from './components'

class App extends Component {
  render() {
    return (
      <CookiesProvider>
        <ActionCableProvider url={'wss://wms-api.tradecron.co/cable'}>
          <Sidebar />
          <LoginModal />
          <main className="main-container">
            <Topbar />
            <Dashboard />
          </main>
        </ActionCableProvider>
      </CookiesProvider>
    )
  }
}

export default App
