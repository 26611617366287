import axios from 'axios'
import { api_url, createProductsTable } from '../utils/helpers'
import { getCookie } from '../utils/cookies'
import FileSaver from 'file-saver'
import { set } from 'lodash'

const token = getCookie('token')

axios.defaults.headers.common['authorization'] = `Bearer ${token}`

export const generatePDF = (id, name, products) => (dispatch, getState) => {
  return new Promise(async resolve => {
    try {
      const html = createProductsTable(name, products)
      await axios
        .post(`${api_url}/orders/pdf`, { html }, { responseType: 'blob' })
        .then(res => {
          FileSaver.saveAs(res.data, `${name}.pdf`)
          resolve()
        })
    } catch (err) {
      dispatch({ type: 'SUPPLY_DOCUMENT_FAILURE', err })
    }
  })
}

export const importSupplyList = () => async dispatch => {
  dispatch({ type: 'SUPPLY_REQUESTING' })

  try {
    const { data } = await axios({
      url: `${api_url}/orders/import`,
      method: 'get'
    })
    dispatch({ type: 'SUPPLY_SUCCESS', data })
  } catch (err) {
    dispatch({ type: 'SUPPLY_FAILURE', err })
  }
}

export const fetchSupplyList = (per_page = 10, page = 1) => async dispatch => {
  dispatch({ type: 'SUPPLY_REQUESTING' })

  try {
    const { data } = await axios({
      url: `${api_url}/orders?per_page=${per_page}&page=${page}`,
      method: 'get'
    })
    dispatch({ type: 'SUPPLY_SUCCESS', data })
  } catch (err) {
    dispatch({ type: 'SUPPLY_FAILURE', err })
  }
}

export const fetchSupplyDocument = id => async dispatch => {
  dispatch({ type: 'SUPPLY_DOCUMENT_REQUESTING', id })
  try {
    const { data } = await axios({
      url: `${api_url}/orders/${id}`,
      method: 'get'
    })
    dispatch({ type: 'SUPPLY_DOCUMENT_SUCCESS', data, id })
  } catch (err) {
    dispatch({ type: 'SUPPLY_DOCUMENT_FAILURE', id, err })
  }
}

export const updateSupplyProduct = (product_id, document_id, amount) => async (
  dispatch,
  getState
) => {
  const document = getState().supply_documents[document_id]
  try {
    const { data } = await axios({
      url: `${api_url}/orders/${document_id}/products/${product_id}`,
      method: 'patch',
      data: {
        real_count: amount
      }
    })
    const product_index = document.data.products.indexOf(
      document.data.products.find(({ id }) => id === product_id)
    )
    set(document, `data.products[${product_index}].status`, data.status)
    dispatch({
      type: 'SUPPLY_DOCUMENT_SUCCESS',
      data: document.data,
      id: document_id
    })
  } catch (err) {
    dispatch({ type: 'SUPPLY_DOCUMENT_FAILURE', id: document_id, err })
  }
}

export const refreshSupplyDocument = id => async (dispatch, getState) => {
  dispatch({
    type: 'SUPPLY_DOCUMENT_REQUESTING',
    id
  })
  try {
    const { data } = await axios({
      url: `${api_url}/orders/${id}/refresh`,
      method: 'get'
    })
    dispatch({
      type: 'SUPPLY_DOCUMENT_SUCCESS',
      data,
      id
    })
  } catch (err) {
    dispatch({ type: 'SUPPLY_DOCUMENT_FAILURE', id, err })
  }
}
