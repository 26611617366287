import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addTab } from '../../../actions/tabs'
import { nav_items } from './nav_items'
import SidebarItem from './components/sidebar_item'

class Sidebar extends Component {
  render() {
    return (
      <aside className="sidebar">
        <header className="sidebar__header">
          <div className="sidebar__logo__wrapper">
            <div className="sidebar__logo">
              <img src={require('./assets/logo.svg')} alt="Logotype" />
            </div>
            <div
              className="label label-sm label-round label-up"
              style={{ backgroundColor: '#ae22ff' }}>
              wms
            </div>
          </div>
          <button className="sidebar__button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              className="kt-svg-icon">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  className="arrow"
                  d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                  fill="#494B74"
                  fillRule="nonzero"
                  transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
                />
                <path
                  className="arrow"
                  d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                  fill="#494B74"
                  fillRule="nonzero"
                  opacity="0.3"
                  transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
                />
              </g>
            </svg>
          </button>
        </header>
        <nav>
          <ul>
            {nav_items.dashboard.map(({ name, path, icon }, id) => (
              <SidebarItem key={id} name={name} path={path} icon={icon} />
            ))}
            <li className="nav-category">Dostawy</li>
            {nav_items.delivery.map(({ name, path, icon }, id) => (
              <SidebarItem key={id} name={name} path={path} icon={icon} />
            ))}
          </ul>
        </nav>
      </aside>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  addTab: tabContent => dispatch(addTab(tabContent))
})

export default connect(null, mapDispatchToProps)(Sidebar)
