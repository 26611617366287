const initialState = {
  readyStatus: 'invalid',
  err: null,
  data: []
}

export default (state = initialState, { data, type }) => {
  switch (type) {
    case 'SUPPLY_REQUESTING':
      return { ...state, readyStatus: 'requesting' }
    case 'SUPPLY_FAILURE':
      return { ...state, readyStatus: 'failure' }
    case 'SUPPLY_SUCCESS':
      return { ...state, readyStatus: 'success', data }
    default:
      return state
  }
}
