const initialState = {
  isOpen: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_LOGIN_MODAL':
      return {
        isOpen: false
      }
    case 'OPEN_LOGIN_MODAL':
      return {
        isOpen: true
      }

    default:
      return state
  }
}
