import React from 'react'
import Loader from 'react-loader-spinner'

const PageLoader = () => {
  return (
    <div className="page-loader">
      <Loader type="Watch" color="rgb(174, 34, 255)" height={100} width={100} />
    </div>
  )
}

export default PageLoader
