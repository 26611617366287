import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import { ActionCable } from 'react-actioncable-provider'

import SupplyDocumentRow from './components/supply_document_row'
import { PageLoader, ModalImage } from '../../../components'
import {
  fetchSupplyDocument,
  refreshSupplyDocument,
  generatePDF
} from '../../../actions/supply'
import { getCookie } from '../../../utils/cookies'

class SupplyDocument extends PureComponent {
  state = {
    isImageModalOpen: false,
    src: null,
    alt: null,
    isBlocked: false,
    isLoading: false
  }

  componentDidMount() {
    const {
      fetchSupplyDocument,
      match: {
        params: { id }
      }
    } = this.props
    fetchSupplyDocument(id)
  }
  // handleReceived = data => {
  //   if (data.error) {
  //     return this.setState({ isBlocked: true })
  //   }
  //   return this.setState({ isBlocked: false })
  // }

  handleOpenImageModal = (src, alt) =>
    this.setState({ isImageModalOpen: true, src, alt })

  handleCloseImageModal = (src, alt) =>
    this.setState({ isImageModalOpen: false, src: null, alt: null })

  render() {
    const { isImageModalOpen, src, alt, isBlocked } = this.state
    const {
      supply_documents,
      refreshSupplyDocument,
      generatePDF,
      match: {
        params: { id }
      }
    } = this.props

    if (this.state.isLoading) {
      return <PageLoader />
    }
    if (!supply_documents || !supply_documents[id]) {
      return <PageLoader />
    }
    if (supply_documents[id].readyStatus === 'requesting') {
      return <PageLoader />
    }
    if (supply_documents[id].readyStatus === 'failure') {
      return <p>Błąd pobierania danych</p>
    }
    const token = getCookie('token')
    const {
      document_number,
      name,
      customer,
      warehouse,
      products
    } = supply_documents[id].data
    return (
      <div className={`dashboard`}>
        {/* <ActionCable
          channel={{
            channel: 'OrderChannel',
            id,
            token
          }}
          onReceived={this.handleReceived}
        /> */}
        <header className="dashboard__header">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              className="kt-svg-icon">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M18.1446364,11.84388 L17.4471627,16.0287218 C17.4463569,16.0335568 17.4455155,16.0383857 17.4446387,16.0432083 C17.345843,16.5865846 16.8252597,16.9469884 16.2818833,16.8481927 L4.91303792,14.7811299 C4.53842737,14.7130189 4.23500006,14.4380834 4.13039941,14.0719812 L2.30560137,7.68518803 C2.28007524,7.59584656 2.26712532,7.50338343 2.26712532,7.4104669 C2.26712532,6.85818215 2.71484057,6.4104669 3.26712532,6.4104669 L16.9929851,6.4104669 L17.606173,3.78251876 C17.7307772,3.24850086 18.2068633,2.87071314 18.7552257,2.87071314 L20.8200821,2.87071314 C21.4717328,2.87071314 22,3.39898039 22,4.05063106 C22,4.70228173 21.4717328,5.23054898 20.8200821,5.23054898 L19.6915238,5.23054898 L18.1446364,11.84388 Z"
                  fill="#5d78ff"
                  opacity="0.3"
                />
                <path
                  d="M6.5,21 C5.67157288,21 5,20.3284271 5,19.5 C5,18.6715729 5.67157288,18 6.5,18 C7.32842712,18 8,18.6715729 8,19.5 C8,20.3284271 7.32842712,21 6.5,21 Z M15.5,21 C14.6715729,21 14,20.3284271 14,19.5 C14,18.6715729 14.6715729,18 15.5,18 C16.3284271,18 17,18.6715729 17,19.5 C17,20.3284271 16.3284271,21 15.5,21 Z"
                  fill="#5d78ff"
                />
              </g>
            </svg>
          </div>
          <h2
            className={`dashboard__title heading ${
              isBlocked ? 'blocked' : ''
            }`}>
            {document_number}{' '}
            {isBlocked ? <span className="error">Zablokowane!!</span> : ''}
          </h2>
          <button className="btn" onClick={() => refreshSupplyDocument(id)}>
            Odśwież zamówienie
          </button>
          <button
            className="btn m-l-15"
            onClick={() => {
              this.setState({ isLoading: true }, () =>
                generatePDF(id, document_number, products).then(() =>
                  this.setState({ isLoading: false })
                )
              )
            }}>
            Generuj PDF
          </button>
        </header>
        <div className="supply-table__header">
          <div className="supply-table__header__document-name">
            <p>
              <strong>Nazwa dokumentu:</strong> {name || 'Brak nazwy'}
            </p>
          </div>
          <div className="supply-table__header__document-info">
            <p>
              <strong>Kontrahent:</strong> {customer || 'Brak kontrahenta'}
            </p>
            <p>
              <strong>Magazyn:</strong> {warehouse || 'Brak magazynu'}
            </p>
          </div>
        </div>
        <table className={`table supply-table`}>
          <thead className="table__header table-head">
            <tr>
              <th>Lp</th>
              <th></th>
              <th>SKU</th>
              <th>Nazwa</th>
              <th>Główny</th>
              <th>Wysyłkowy</th>
              <th>Zam</th>
              <th>Ilość</th>
            </tr>
          </thead>
          <tbody className="table__body table-body">
            {products.map((product, index) => (
              <SupplyDocumentRow
                form={String(product.id)}
                isBlocked={isBlocked}
                key={product.id}
                openModal={this.handleOpenImageModal}
                initialValues={{ amount: product.real_count }}
                data={{ ...product, lp: index + 1 }}
              />
            ))}
          </tbody>
        </table>
        {isImageModalOpen ? (
          <ModalImage
            handleClose={this.handleCloseImageModal}
            src={src}
            alt={alt}
          />
        ) : null}
      </div>
    )
  }
}
const mapStateToProps = ({ supply_documents }) => ({
  supply_documents
})

const mapDispatchToProps = dispatch => ({
  fetchSupplyDocument: id => dispatch(fetchSupplyDocument(id)),
  generatePDF: (id, name, products) =>
    dispatch(generatePDF(id, name, products)),
  refreshSupplyDocument: id => dispatch(refreshSupplyDocument(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SupplyDocument))
