import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { openLoginModal, handleLogout } from '../../../actions/login_modal'
import { getCookie } from '../../../utils/cookies'
import TabItem from './components/tab_item'

class Topbar extends Component {
  render() {
    const {
      tabs,
      location: { pathname },
      openLoginModal,
      handleLogout
    } = this.props
    return (
      <nav className="main-navbar">
        <div className="main-navbar__top">
          <button className="nav-button">
            <div className="nav-button__logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <rect
                    className="svg-rect"
                    fill="#FFFFFF"
                    x="4"
                    y="4"
                    width="7"
                    height="7"
                    rx="1.5"
                  />
                  <path
                    d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                    fill="#FFFFFF"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </div>
            <span className="navbar-text">Zmień aplikację</span>
          </button>

          <div className="main-navbar__app-name">
            <div
              className="label label-sm label-bold label-round label-up"
              style={{ backgroundColor: '#fff', color: '#AE22FF' }}>
              wms
            </div>
            <span className="navbar-text">v. 1.03</span>
          </div>
          {getCookie('token') ? (
            <button
              className="nav-button"
              onClick={handleLogout}>
              <div className="nav-button__logo nav-button__logo--right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="kt-svg-icon">
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
              <span className="navbar-text">Wyloguj</span>
            </button>
          ) : (
            <button className="nav-button" onClick={openLoginModal}>
              <div className="nav-button__logo nav-button__logo--right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="kt-svg-icon">
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
              <span className="navbar-text">Konto</span>
            </button>
          )}
        </div>
        <div className="main-navbar__bottom">
          <ul className="tab-list">
            {tabs &&
              tabs.openedTabs.length > 0 &&
              tabs.openedTabs.map(({ name, path, icon }, id) => (
                <TabItem
                  key={id}
                  name={name}
                  isActive={pathname === path}
                  icon={icon}
                  path={path}
                  items={tabs.openedTabs.length}
                />
              ))}
          </ul>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = ({ tabs }) => ({
  tabs
})

const mapDispatchToProps = dispatch => ({
  openLoginModal: () => dispatch(openLoginModal()),
  handleLogout: () => dispatch(handleLogout())
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar))
