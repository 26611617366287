import React, { Component } from 'react'
import { FormItem } from '../../components'

export default class Modal extends Component {
  constructor(props) {
    super(props)
    this.icon = {
      delete_blue: require('../../images/icons/delete-blue.svg'),
      delete_white: require('../../images/icons/delete-white.svg'),
      exit: require('../../images/icons/exit.svg'),
      currency: require('../../images/icons/currency.svg')
    }
  }
  render() {
    const { type, header, icon, handleClose } = this.props
    switch (type) {
      case 'delete':
        return (
          <div className="popup-wrapper">
            <div className="popup">
              <header className="popup__header">
                <div className="icon__container">
                  <img src={this.icon.delete_blue} alt="" />
                </div>
                <h2 className="dashboard__title heading">Usuń</h2>
                <button onClick={handleClose} className="button">
                  <div className="icon__container">
                    <img src={this.icon.exit} alt="" />
                  </div>
                </button>
              </header>
              <section className="popup__body">
                <p className="popup-text">
                  Czy na pewno chcesz usunąć <strong>[nazwa itemu]</strong>
                </p>
              </section>
              <div className="popup__footer">
                <button
                  onClick={handleClose}
                  className="button button__large button--cancel">
                  Nie usuwaj
                </button>
                <button
                  onClick={handleClose}
                  className="button button__large button--pink-light">
                  <span>
                    <img src={this.icon.delete_white} alt="" />
                  </span>
                  Tak, usuń
                </button>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className="popup-wrapper">
            <div className="popup">
              <header className="popup__header">
                <div className="icon__container">
                  <img src={icon} alt="" />
                </div>
                <h2 className="dashboard__title heading">{header}</h2>
                <button onClick={handleClose} className="button">
                  <div className="icon__container">
                    <img src={this.icon.exit} alt="" />
                  </div>
                </button>
              </header>
              <section className="popup__body">
                {(function() {
                  switch (type) {
                    case 'currency':
                      return (
                        <form action="" className="dashboard__form">
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName: 'Waluta',
                              isSelect: true
                            }}
                          />

                          <FormItem
                            settings={{
                              columns: 1,
                              labelName: 'Skrót'
                            }}
                          />
                        </form>
                      )
                    case 'shop':
                      return (
                        <form action="" className="dashboard__form">
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName:
                                'Wybierz platformę na której prowadzisz sprzedaż',
                              isSelect: true
                            }}
                          />
                        </form>
                      )
                    case 'channel':
                      return (
                        <form action="" className="dashboard__form">
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName:
                                'Wybierz kanał, na którym znajduje się produkt',
                              isSelect: true
                            }}
                          />
                        </form>
                      )
                    case 'language':
                      return (
                        <form action="" className="dashboard__form">
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName: 'Język',
                              isSelect: true
                            }}
                          />
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName: 'Skrót'
                            }}
                          />
                        </form>
                      )
                    case 'platform':
                      return (
                        <form action="" className="dashboard__form">
                          <FormItem
                            settings={{
                              columns: 1,
                              labelName:
                                'Wybierz platformę, na której prowadzisz sprzedaż',
                              isSelect: true
                            }}
                          />
                        </form>
                      )
                    default:
                      return null
                  }
                })()}
              </section>
              <div className="popup__footer">
                <button
                  onClick={handleClose}
                  className="button button__large button--cancel">
                  Anuluj
                </button>
                <button className="button button__large button--plus button--green">
                  Dodaj
                </button>
              </div>
            </div>
          </div>
        )
    }
  }
}
