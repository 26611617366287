export const api_url = `https://wms-api.tradecron.co/v1`

export const parse_date = date => {
  if (date) {
    const parsed_date = date
      .split('T')[0]
      .split('-')
      .join('.')
    const parsed_time = date.split('T')[1].split('.')[0]
    return `${parsed_date} ${parsed_time}`
  }
  return 'brak daty'
}

export const createProductsTable = (name, products) => `
  <!DOCTYPE html>
  <html>
    <head>
    <title>${name}</title>
    <meta charset="utf-8" />
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <style>
      .title{
        text-align: center;
        padding: 30px 0;
      }
      .title h1{
        font-size: 30px;
        line-height: 40px;
      }
      .table tbody td{
        padding: 2px 20px;
        font-size: 18px;
        line-height: 25px;
        vertical-align: middle;

      }
      .table tbody td img{
        max-width: 125px;
      }
    </style>
    </head>
    <body>
    <header class="title">
      <h1 class="title">${name}</h1>
    </header>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Lp</th>
          <th></th>
          <th>Nazwa</th>
          <th>Zam</th>
        </tr>
      </thead>
      <tbody>
      ${products
        .map(
          (item, index) => `<tr>
          <td style="height: 100">${index + 1}</td>
          <td style="height: 100"><img src="${item.image}" /></td>
          <td style="height: 100">${item.name}</td>
          <td style="height: 100">${item.count}</td>
        </tr>`
        )
        .join('\n')}
    
      </tbody>
    </table>
    </body>
  </html>
  `
