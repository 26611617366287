import React from 'react'
import Select from 'react-select'

export default function FormItem({
  settings: {
    htmlFor,
    columns,
    labelName,
    type,
    name,
    hidden,
    isSelect,
    options,
    prefix,
    postfix,
    disabled,
    placeholder
  },
  onChange
}) {
  if (!isSelect) {
    return (
      <div className="form-group">
        <label htmlFor={htmlFor ? htmlFor : 'htmlFor'} className="label-form">
          {labelName ? labelName : 'Label'}
        </label>
        <div className="inp-group">
          {prefix && <span className="form-span prefix">{prefix}</span>}
          <input
            type={type ? type : 'text'}
            id={htmlFor}
            name={name ? name : htmlFor}
            placeholder={placeholder ? placeholder : ''}
            className={disabled ? 'input-disabled' : ''}
            disabled={disabled}
          />
          {postfix && (
            <span className="form-span postfix">
              {postfix === 'color' ? (
                <div className="postfix-color"></div>
              ) : (
                postfix
              )}
            </span>
          )}
        </div>
      </div>
    )
  } else {
    const customStyles = {
      option: provided => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '13px'
      }),
      singleValue: provided => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '13px',
        padding: '4px'
      }),
      indicatorSeparator: styles => ({ ...styles, display: 'none' }),
      placeholder: provided => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '13px'
      })
    }
    return (
      <div className="form-group">
        <label className="label-form">{labelName}</label>
        <Select
          placeholder="Wybierz..."
          styles={customStyles}
          options={options}
          onChange={onChange || null}
          theme={theme => ({
            ...theme,
            fontFamily: 'Poppins',
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: '#E2E5EC'
            }
          })}
        />
      </div>
    )
  }
}
