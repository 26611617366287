import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'

import { updateSupplyProduct } from '../../../../actions/supply'

const InputField = ({
  input,
  meta,
  type,
  status,
  submit,
  defaultValue,
  submitted,
  isBlocked
}) => {
  const [focus, setFocus] = useState(false)
  return (
    <div className="form-group save-group">
      <div className={`inp-group  ${status !== null ? status : ''}`}>
        <input
          {...input}
          disabled={isBlocked}
          onFocus={() => setFocus(true)}
          onBlur={() =>
            defaultValue === Number(input.value) &&
            setTimeout(() => {
              setFocus(false)
            }, 200)
          }
          type={type}
        />
      </div>
      <button
        onClick={() => {
          submit()
          setFocus(false)
        }}
        className="btn-save"
        disabled={!focus || isBlocked}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20">
          <path fill="none" d="M0,0H20V20H0Z" />
          <path
            fill="#fff"
            d="M14.833,4H5.667A1.53,1.53,0,0,0,4,5.667v10a1.53,1.53,0,0,0,1.667,1.667h10a1.533,1.533,0,0,0,1.667-1.667V6.673a.417.417,0,0,0-.122-.295Zm0,5.833H6.5V4h8.333Z"
            transform="translate(-0.667 -0.667)"
          />
          <rect
            fill="#fff"
            width="2.5"
            style={{ opacity: 0.3 }}
            height="4.167"
            rx="0.5"
            transform="translate(10 3.333)"
          />
        </svg>
      </button>
    </div>
  )
}

// const checkImage = src => {
//   fetch(src, { method: 'HEAD' })
//     .then(res => {
//       if (res.ok) {
//         return src
//       } else {
//         return require('../../../../images/icons/image-placeholder.png')
//       }
//     })
//     .catch(err => console.log('Error:', err))
//   return src
// }

class SupplyDocumentRow extends Component {
  submit = values => {
    const { amount } = values
    const {
      updateSupplyProduct,
      data: { id },
      match: { params },
      isBlocked
    } = this.props
    if (!isBlocked) {
      updateSupplyProduct(id, params.id, amount)
    } else {
      alert('zablokowane')
    }
  }
  render() {
    const {
      openModal,
      handleSubmit,
      isBlocked,
      data: {
        lp,
        name,
        sku,
        main_number,
        shipping_number,
        count,
        real_count,
        status,
        submitted,
        image
      }
    } = this.props
    return (
      <tr>
        <td className={`number ${status ? status : ''}`}>
          {status && status === 'error' ? (
            <img src={require('../assets/error_icon.svg')} alt="" />
          ) : (
            <span>{lp}</span>
          )}
        </td>
        <td className="image-box">
          <button
            className="modal__trigger"
            onClick={() => openModal(image, name)}
            style={{
              backgroundImage: `url('${image}')`
            }}
          />
        </td>
        <td>{sku}</td>
        <td>{name}</td>
        <td>{main_number || '---'}</td>
        <td>{shipping_number}</td>
        <td>{count}</td>
        <td>
          <form onSubmit={handleSubmit(this.submit)}>
            <Field
              name="amount"
              status={status}
              submitted={submitted}
              defaultValue={real_count ? real_count : 0}
              component={InputField}
              isBlocked={isBlocked}
              submit={handleSubmit(this.submit)}
              type="number"
            />
          </form>
        </td>
      </tr>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  updateSupplyProduct: (id, params_id, amount) =>
    dispatch(updateSupplyProduct(id, params_id, amount))
})
export default connect(
  null,
  mapDispatchToProps
)(reduxForm()(withRouter(SupplyDocumentRow)))
