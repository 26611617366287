import React from 'react'
import { withRouter } from 'react-router-dom'
import { addTab, switchTab } from '../../../../../actions/tabs'
import { connect } from 'react-redux'

function SidebarItem({
  name,
  path,
  icon,
  addTab,
  history,
  switchTab,
  location,
  tabs: { openedTabs }
}) {
  const isOpened = !openedTabs.filter(item => item.name === name).length
  const handleBtnPlusClick = function() {
    addTab({ name: name, path: path, icon: icon })
  }
  function handleMiddleClick(e) {
    if (e.button === 1) {
      e.preventDefault()
      if (isOpened) {
        addTab({ name: name, path: path, icon: icon })
      }
    }
  }

  return (
    <li
      onMouseDown={handleMiddleClick}
      className="nav-item">
      <div className="nav-item__icon">{icon}</div>
      <p
        onClick={() =>
          !isOpened
            ? history.push(path)
            : switchTab(location.pathname, { name, path, icon }).then(path =>
                history.push(path)
              )
        }
        className="nav-item__title">
        {name}
      </p>
      {isOpened && (
        <button
          onClick={handleBtnPlusClick}
          className="nav-item__button"></button>
      )}
    </li>
  )
}

const mapDispatchToProps = dispatch => ({
  addTab: tabContent => dispatch(addTab(tabContent)),
  switchTab: (activeTab, newTab) => dispatch(switchTab(activeTab, newTab))
})

const mapStateToProps = ({ tabs }) => ({
  tabs
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarItem))
