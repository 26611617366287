import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { removeTab } from '../../../../../actions/tabs'

function TabItem({ name, path, isActive, icon, items, removeTab }) {
  return (
    <li
      className={`tab-list__item ${
        isActive ? `tab-list__item--active` : null
      } `}
      style={{ maxWidth: items > 7 ? '70px' : 'initial' }}>
      <Link to={path}>
        <div className="tab-list__logo">{icon}</div>
        {items < 8 && (
          <span className="tab-list__text navbar-text">{name}</span>
        )}
        <button
          onClick={() => removeTab(name)}
          className="tab-list__close"></button>
      </Link>
    </li>
  )
}

const mapDispatchToProps = dispatch => ({
  removeTab: tabName => dispatch(removeTab(tabName))
})

export default connect(null, mapDispatchToProps)(TabItem)
