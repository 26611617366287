import React from 'react'
import { ListItem } from '../../components'

export default class Tree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.data })
  }
  render() {
    return (
      <div className="categories">
        <ul className="categories__list">
          <ListItem
            name="Platforma 1"
            data={['4565218', 'xyz', '120']}
            tree_box="tree-minus"
            level={1}
            children={[
              {
                name: 'Platforma 1.1',
                data: ['4565218', 'xyz', '120'],
                level: 2
              },
              {
                name: 'Platforma 1.2',
                data: ['4565218', 'xyz', '120'],
                tree_box: 'tree-minus',
                level: 2,
                children: [
                  {
                    name: 'Platforma 1.2.1',
                    data: ['4565218', 'xyz', '120'],
                    level: 3
                  },
                  {
                    name: 'Platforma 1.2.2',
                    data: ['4565218', 'xyz', '120'],
                    tree_box: 'tree-plus',
                    level: 3
                  }
                ]
              },
              {
                name: 'Platforma 1.3',
                data: ['4565218', 'xyz', '120'],
                level: 2
              }
            ]}
          />
          <ListItem
            name="Platforma 5"
            data={['4565218', 'xyz', '120']}
            level={1}
          />
        </ul>
      </div>
    )
  }
}
