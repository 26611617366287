import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { handleLogin, closeLoginModal } from '../../../actions/login_modal'

const InputField = ({ input, meta, type, label }) => (
  <div className="form-group">
    <label htmlFor="">{label}</label>
    <div className="inp-group">
      <input {...input} type={type} />
    </div>
  </div>
)

class LoginModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    setTimeout(() => {
      this.setState({ active: true })
    }, 200)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeLoginModal()
    }
  }
  submit = values => {
    this.props.handleLogin(values)
  }

  render() {
    const {
      closeLoginModal,
      login_modal: { isOpen },
      handleSubmit
    } = this.props
    const { active } = this.state
    return isOpen ? (
      <div className="popup-wrapper">
        <div
          className={`popup popup__login ${active ? 'active' : ''}`}
          ref={node => (this.wrapperRef = node)}>
          <div className="popup__header">
            <button className="button" onClick={closeLoginModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon">
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fill-rule="evenodd">
                  <g
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                    fill="#A2A5B9">
                    <rect x="0" y="7" width="16" height="2" rx="1" />
                    <rect
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      x="0"
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </g>
                </g>
              </svg>
            </button>
          </div>
          <div className="popup__body">
            <form onSubmit={handleSubmit(this.submit)}>
              <Field
                name="email"
                label="E-mail"
                component={InputField}
                type="email"
              />
              <Field
                name="password"
                label="Hasło"
                component={InputField}
                type="password"
              />
              <div className="login_button">
                <button class="btn" type="submit">
                  Zaloguj
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = ({ login_modal }) => ({ login_modal })

const mapDispatchToProps = dispatch => ({
  closeLoginModal: () => dispatch(closeLoginModal()),
  handleLogin: val => dispatch(handleLogin(val))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'login-modal' })(LoginModal))
