import React from 'react'
import {
  Dashboard,
  Shop,
  // Warehouse,
  // Channel,
  // Platform,
  // CurrencyLang,
  // Forwarders,
  // Product,
  // Categories,
  // Producent,
  // Permittedip
} from '../../../icons'

export const nav_items = {
  dashboard: [{ name: 'Dashboard', path: '/', icon: <Dashboard /> }],
  delivery: [{ name: 'Obsługa dostaw', path: '/supply', icon: <Shop /> }]
}
