const initialState = {
  err: null,
  openedTabs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TAB': {
      state.openedTabs.push(action.tabContent)
      return { ...state, openedTabs: state.openedTabs }
    }

    case 'REMOVE_TAB': {
      const newTabs = state.openedTabs.filter(
        ({ name }) => name !== action.name
      )
      return {
        ...state,
        openedTabs: newTabs
      }
    }
    case 'SWITCH_TAB': {
      const { activeTab, newTab } = action
      let openedTabs = state.openedTabs.filter(({ path }) => path !== activeTab)
      return {
        ...state,
        openedTabs: [...openedTabs, newTab]
      }
    }
    default:
      return state
  }
}
