import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { SupplyList, SupplyDocument } from '../../../pages'

export default class Dashboard extends Component {
  render() {
    return (
      <section className="dashboard-container">
        <Switch>
          <Route exact={true} path="/supply" component={SupplyList} />
          <Route exact={true} path="/supply/:id" component={SupplyDocument} />
        </Switch>
      </section>
    )
  }
}
