export const addTab = tabContent => (dispatch, getState) => {
  const { router } = getState()
  const activeRoute = router.location.pathname
  dispatch({ type: 'ADD_TAB', tabContent, activeRoute })
}

export const removeTab = name => dispatch => {
  dispatch({ type: 'REMOVE_TAB', name })
}

export const switchTab = (activeTab, newTab) => dispatch => {
  return new Promise(resolve => {
    dispatch({ type: 'SWITCH_TAB', activeTab, newTab })
    resolve(newTab.path)
  })
}
