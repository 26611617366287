import axios from 'axios'
import { api_url } from '../utils/helpers'
import { removeCookie, setCookie } from '../utils/cookies'

export const handleLogin = data => dispatch => {
  axios
    .post(`${api_url}/sign_in`, { ...data })
    .then(({ data }) =>
      setCookie('token', data.token).then(
        () => (window.location.pathname = '/')
      )
    )
}
export const handleLogout = () => dispatch => {
  removeCookie('token').then(() => (window.location.pathname = '/'))
}

export const closeLoginModal = () => async dispatch => {
  dispatch({ type: 'CLOSE_LOGIN_MODAL' })
}

export const openLoginModal = () => async dispatch => {
  dispatch({ type: 'OPEN_LOGIN_MODAL' })
}
